/* ChatBubble.css */

.chat-bubble {
    position: relative;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    max-width: 90%;
    margin: 10px;
}

.own-message {
    background-color: #8ec4fd;
    color: white;
    align-self: flex-end;
}

.other-message {
    background-color: #c8e6fc;
    color: black;
}

.bubble-tail {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: inherit;
    transform: rotate(45deg);
    bottom: -10px;
}

.own-tail {
    right: -10px;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.other-tail {
    left: -10px;
    clip-path: polygon(0 0, 100% 0, 0% 100%);
}