.widget-card {
    height: 150px;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    border: 0px solid transparent;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.widget-card:hover {
    transform: scale(1.05);
    border: 1px solid white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}