@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Titillium+Web&display=swap');
/* fonts.css */
@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-Light.otf') format('opentype');
    /* Add additional font formats if necessary */
    /* font-weight: normal; */
    font-style: normal;
}
* {
    font-family: 'Gilroy', sans-serif !important;
    font-size: 13px !important;
}

.bg-blur {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

body>div.fade.modal.show>div>div>div.modal-body>div>iframe {
    min-height: 500px;
}

.hover-button {
    transition: all 0.5s ease;
}

.hover-button:hover {
    transform: scale(1.2);
}

.custom-backdrop {
    /* Set your desired background color with some transparency to lighten it */
    background-color: rgba(160, 160, 160, 0.461) !important;
    /* Light gray with 60% opacity */
}

/* custom.css */
.custom-offcanvas {
    width: 800px !important;
    /* Set your desired width here */
}

.custom-cell-width {
    width: 150px;
    /* Adjust the width as needed */
    min-width: 150px;
    /* Ensure a minimum width to avoid excessive shrinking */
}

.widget-card-blur {
    /* From https://css.glass */
    background: rgba(144, 144, 144, 0.07);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.6px);
    -webkit-backdrop-filter: blur(6.6px);
    border: 1px solid rgba(144, 144, 144, 0.3);
}

.hover-qty:hover {
    transform: scale(1.2);
    cursor: pointer;
    transition: transform 0.5s ease;
    background-color: #AFDBF5;
    color: black;
    display: inline-block;
    padding: 10px;
    border-radius: 3px;
}
.three-d-box-shadow {
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Add this CSS to your stylesheet or styled-components */
.blur-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.101);
    /* Adjust the background color and opacity as needed */
    backdrop-filter: blur(5px);
    /* Adjust the blur intensity as needed */
    z-index: 2;
    /* Ensure it's on top of the component */
}